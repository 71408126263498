<div class="paywall py-5">
  <div class="gradient">
  <div class="container d-flex flex-column align-items-center">
    <div class="plan-logo">
      <img width="60" src="assets/img/logo-simple.png" />
    </div>
    <div class="position-relative col-md-6">
      <img src="assets/img/torus2.svg" class="left-image" alt="left">
      <img src="assets/img/torushalf.svg" class="right-image" alt="right-image">
      <form class="mt-4 border mb-4">
        <div class="plan-header">
          <h4 class="text-primary-dark fw-bold px-4 px-md-5 pt-4 pb-3">Contratación plan</h4>
        </div>
        <div class="text-center w-75 mx-auto pt-4">
          <svg class="my-4" width="66" height="66" viewBox="0 0 66 66" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M33 65.5C15.0503 65.5 0.5 50.9497 0.5 33C0.5 15.0503 15.0503 0.5 33 0.5C50.9497 0.5 65.5 15.0503 65.5 33C65.5 50.9497 50.9497 65.5 33 65.5ZM33 59C39.8956 59 46.5088 56.2607 51.3848 51.3848C56.2607 46.5088 59 39.8956 59 33C59 26.1044 56.2607 19.4912 51.3848 14.6152C46.5088 9.73928 39.8956 7 33 7C26.1044 7 19.4912 9.73928 14.6152 14.6152C9.73928 19.4912 7 26.1044 7 33C7 39.8956 9.73928 46.5088 14.6152 51.3848C19.4912 56.2607 26.1044 59 33 59ZM29.7598 46L15.97 32.2103L20.5655 27.6148L29.7598 36.809L48.1418 18.4237L52.7405 23.0192L29.7598 46Z" fill="#42C947"/>
          </svg>
          <h3 class="mb-3">¡Tu pago fue exitoso!</h3>
          <p class="fs-5 text-center mb-5">En breve enviaremos tu acceso a tu email.
            Podría llegar a tu folder de spam.</p>
        </div>
        <div class="mb-4 border-top w-100 pt-4 d-flex flex-column align-items-center">
            <a href="https://wa.me/5212211048178?text=Hola%21%20Acabo%20de%20%F0%9F%92%B8%20pagar%20mi%20plan.%20Tengo%20dudas%20y%2Fo%20necesito%20soporte%20t%C3%A9cnico%20%F0%9F%86%98" class="btn btn-primary text-white">Soporte en Whatsapp</a>
        </div>
      </form>
    </div>
    <div class="fs-md-5 text-primary-dark mb-3 plan-monitoreo">
      #ElMonitoreoInteligente 🧠
    </div>
  </div>
  </div>
</div>