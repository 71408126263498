import { Component } from '@angular/core';
import { AuthService } from './shared/services/auth.service';
import { SidebarService } from './shared/services/sidebar.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'brifmedia';
  menuItems = [
    {
      title: 'Favoritos',
      link: '/favs',
      icon: 'heart'
    },{
      title: 'Buscar',
      link: '/search',
      icon: 'search'
    },{
      title: 'Portadas',
      link: '/covers',
      icon: 'book'
    },{
      title: 'Columnas',
      link: '/opinion',
      icon: 'align-left'
    },{
      title: 'Videos',
      link: '/videos',
      icon: 'play'
    },{
      title: 'Monitoreo',
      link: '/monitoring',
      icon: 'bar-chart-2'
    }
  ]
  open: boolean = false;
  constructor(public authService: AuthService, private sidebarService: SidebarService) {
    this.sidebarService.sidebarState.subscribe(open => {
      this.open = open
    })
  }

  isVisible(): boolean {
    const forbidden = document.location.href.includes('pago') || document.location.href.includes('registro')
    return !forbidden;
  }

  close() {
    this.sidebarService.sidebarState.next(false)
  }

  logOut() {
    this.authService.logout()
  }
}
