<div class="paywall">
  <div class="gradient">
  <div class="container d-flex flex-column align-items-center">
    <img width="70" class="mt-5" src="assets/img/logo-simple.png" />
    <app-plan-selector (nextButton)="onGoNext($event)" *ngIf="step===1"></app-plan-selector>
    <div class="w-lg-50" *ngIf="step===2">
      <app-client-form
        (clientChanges)="onClientChanges($event)"
        (backButton)="onGoBack()"
        (submitButton)="onSubmitData()"
        [errorRequest]="errorRequest"
        [loading]="loading"
      >
    </app-client-form>
    </div>
  </div>
  </div>
</div>
