<div class="card card-column h-100 mb-1 d-flex justify-content-between position-relative overflow-hidden"
    [routerLink]="'/videos/id/'+column._id"
    [queryParams]="{time: (comment ? comment.time : null)}"
    *ngIf="column">
    <div class="video-poster">
        <div class="video-backdrop ratio ratio-16x9">
        </div>
        <div class="video-content ratio ratio-16x9">
            <video *ngIf="!column.thumbnail" preload="metadata" [src]="column.video+'#t=0.001' | image"></video>
            <img class="w-100" *ngIf="column.thumbnail" [src]="column.thumbnail | image">
        </div>
        <div class="video-title">
            <app-feather-icons icon="play"></app-feather-icons>
            <h6 class="mt-3 mb-0 title-font">{{column.title}}</h6>
        </div>
    </div>
    <div class="p-2">
        <p class="text-muted-card mb-2" [innerHTML]="(comment ? comment.title : column.description) | limit: 85">
        </p>
        <span *ngIf="showDate">
            {{column.createdAt | dateAgo}}
        </span>
    </div>
    <div class="w-100 mt-1">
        <div class="d-flex justify-content-between align-items-center">
            <div class="d-flex" style="font-size: 1.25em;">
                <app-perception perception="{{comment.perceptions}}" *ngIf="comment"></app-perception>
            </div>
            <span class="text-primary-dark fw-900 me-2">
                VIDEO
            </span>
        </div>
    </div>
    <div class="border-right-card"></div>
</div>
