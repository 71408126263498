<mat-sidenav-container>
    <mat-sidenav #sidenav="matSidenav" class="mat-elevation-z8" [opened]="open" (closedStart)="close()">
        <div class="container position-relative">
            <div class="bg-white text-primary-dark close-button d-flex justify-content-center align-items-center" (click)="sidenav.toggle()">
                <i class="fa fa-times fa-lg"></i>
            </div>
            <div class="pt-5">
                <h2 class="p-3">
                    Menú
                </h2>
                <button mat-button class="w-100 mb-3 pl-0 text-start" routerLink="/como-usar" (click)="sidenav.toggle()">
                    <app-feather-icons icon="help-circle"></app-feather-icons>&nbsp;
                    <span>Cómo usar</span>
                </button>
                <button mat-button class="w-100 mb-3 pl-0 text-start" routerLink="/configuration" (click)="sidenav.toggle()">
                    <app-feather-icons icon="settings"></app-feather-icons>&nbsp;
                    <span>Configuración</span>
                </button>
                <button mat-button class="w-100 mb-3 pl-0 text-start" (click)="logOut()">
                    <app-feather-icons icon="power" class="mr-2"></app-feather-icons>&nbsp;
                    <span>Cerrar sesión</span>
                </button>
            </div>
        </div>

    </mat-sidenav>
    <mat-sidenav-content>
        <div class="app-container">

        <div class="app-content">
            <router-outlet></router-outlet>
        </div>

        <app-install-prompt></app-install-prompt>

        <div class="tab-bar" *ngIf="authService.isLoggedIn && isVisible()">
            <div class="tab-button" [routerLink]="item.link" *ngFor="let item of menuItems" routerLinkActive="active">
                <app-feather-icons [icon]="item.icon"></app-feather-icons>
                <span class="fw-bold">{{item.title}}</span>
            </div>
        </div>
        </div>
    </mat-sidenav-content>
</mat-sidenav-container>
