import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-no-info',
  templateUrl: './no-info.component.html',
})
export class NoInfoComponent {
    @Input() text = 'No hay información hoy';
    @Input() favorite = false;
    @Input() favoriteLink = '';
}
