import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { throwError } from 'rxjs';
import { PaywallService } from './paywall.service';

export interface ClientObj {
  type: string;
  value: string;
 }
export interface PaymentBody {
  label: string;
  email: string;
  line_items: [{
    name: string;
    quantity: number;
    unit_price: number;
  }];
  subscribe?: boolean;
}

export interface SubscriptionOrder {
  name: string;
  label: string;
  planId: string;
  customer: {
    email: string;
    name: string;
    phone: string;
  }
  line_items: [{
    name: string;
    quantity: number;
    unit_price: number;
  }];
  subscribe?: boolean;
  licences: number;
}

export enum PlanTypes {
  BasicoA = 'basicoA',
  BasicoM = 'basicoM',
  PremiumA = 'premiumA',
  PremiumM = 'premiumM',
  EstandarA = 'estandarA',
  EstandarM = 'estandarM',
}

@Component({
  selector: 'app-paywall',
  templateUrl: './paywall.component.html',
  styleUrls: ['./paywall.component.scss']
})
export class PaywallComponent implements OnInit {

  errorRequest: string;
  loading: boolean;
  planType: string;
  step: number;

  client = {
    email: ''
  };

  planDetails = {
    estandarA: {
      name: 'Plan Estándar Anual',
      price: 3800_00,
    },
    estandarM: {
      name: 'Plan Estándar Mensual',
      price: 399_00,
    },
    basicoA: {
      name: 'Plan Básico Anual',
      price: 1900_00,
    },
    basicoM: {
      name: 'Plan Básico Mensual',
      price: 199_00,
    },
    premiumA: {
      name: 'Plan Premium Anual',
      price: 7500_00,
    },
    premiumM: {
      name: 'Plan Premium Mensual',
      price: 799_00,
    }
  }

  constructor(
    public paywallService: PaywallService,
    private router: Router
  ) { }

  onClientChanges($event: ClientObj) {
    const {type, value} = $event;
    switch (type) {
      case 'email':
        this.client.email = value;
        break;
      default:
        break;
    }
  }

  async onSubmitData() {
    const body: PaymentBody = {
      label: this.planType,
      email: this.client.email,
      line_items: [{
        name: this.planDetails[this.planType as PlanTypes].name,
        quantity: 1,
        unit_price: this.planDetails[this.planType as PlanTypes].price
      }],
      subscribe: true,
    }
    try {
      this.loading = true;
      const data: any = await this.paywallService.startPayment(body);
      window.open(data.checkout.url);
      this.loading = false;
    } catch (errorResponse: any) {
      if (errorResponse.error?.message === "Client doesn't exist") {
         this.router.navigate(['/registro'], { state: {
          type: 'personal',
          data: {
            paymentDetails: body
          }
        }});
      }
      this.loading = false;
      this.errorRequest = 'Ha ocurrido un error procesando el pago';
    }
  }

  handleError() {
    return throwError('Error')
  }

  onGoBack() {
    this.step = 1;
  }

  onGoNext(plan: string) {
    this.planType = plan;
    this.step = 2;
  }

  ngOnInit(): void {
    this.step = 1;
  }

}
