<div class="register">
  <div class="gradient">
  <div class="container d-flex flex-column align-items-center">
    <img width="70" class="mt-5" src="assets/img/logo-simple.png" />
    <form class="bg-white mt-4 p-4 border rounded mb-5 w-75">
      <div>
        <h4 class="title text-primary fw-bold pb-3">¡Registro exitoso!</h4>
      </div>
      <h6 class="subtitle pb-3">Hemos creado tu cuenta satisfactoriamente. <span *ngIf="type === 'personal'">Ahora procedamos a realizar tu pago.</span></h6>
      <a class="border-0 bg-primary text-white p-2 rounded" *ngIf="type === 'personal'" href="/pago">
        Pagar suscripción
      </a>
      <a class="border-0 bg-primary text-white p-2 rounded" *ngIf="type !== 'personal'" href="/login">
          Iniciar sesión
      </a>
    </form>
  </div>
  </div>
</div>
