import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-video-card',
  templateUrl: './video-card.component.html',
  styleUrls: ['./video-card.component.scss']
})
export class VideoCardComponent implements OnInit {

  @Input('video') column: any = null
  @Input() tagPerception!: string
  @Input() showDate: boolean = true
  path: string = ''
  comment: any;

  constructor() { }

  ngOnInit() {
    this.findCommentOfTag()
  }

  findCommentOfTag() {
    if (this.tagPerception) {
      const comment = this.column.comments.find(
        (el: any) => el.tags.find((tag: { tag: string; }) => tag.tag == this.tagPerception) 
      )
      let tags = comment?.tags.filter((el: any) => el.tag === this.tagPerception)
      if (tags.length > 0){
        this.comment = {
          title: comment.title,
          perceptions: tags[0].perceptions,
          time: comment.start,
        }
      }
    }
  }

}
