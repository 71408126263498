import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-register-success',
  templateUrl: './register-success.component.html',
  styleUrls: ['./register-success.component.scss']
})
export class RegisterSuccessComponent {

  type: string;

  constructor(public router: Router) {
      const state = this.router.getCurrentNavigation()?.extras?.state as any;
      if (state) {
          this.type = state.type;
      }
  }

}
