<div class="page-wrapper">
  <div class="container-fluid p-0">
    <div class="row">
      <div class="col-12">     
        <div class="login-card">
          <div class="card-body">
            <div class="login-main text-center"> 
              <form class="theme-form p-3">
                <h4>Estamos para ayudarte</h4>
                <p>Comunícate con nosotros</p>
                <p>Puedes contactarnos mediante el siguiente correo electronico</p>
                <p>
                  <a class="h4" href="mailto:nunez@kreacialabs.tech">nunez@kreacialabs.tech</a>
                </p>
                <p>Indicando que problema presentas y lo solucionaremos lo mas pronto posible</p>
                <a class="h6 text-decoration-none" routerLink="/auth/login"><i class="fa fa-arrow-left"></i> Volver</a>
                <!-- <div class="form-group">
                  <label class="col-form-label">Enter Your Mobile Number</label>
                  <div class="row">
                    <div class="col-md-3">
                      <input class="form-control mb-1" type="text" value="+ 91">
                    </div>
                    <div class="col-md-9">
                      <input class="form-control mb-1" type="tel" value="000-000-0000">
                    </div>
                    <div class="col-12">
                      <button class="btn btn-primary btn-block m-t-10" type="submit">Send</button>
                    </div>
                  </div>
                </div> -->
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>