import { Component } from '@angular/core';

@Component({
  selector: 'app-suscribe',
  template: `<div class="suscribe-block">
    <p class="text-dark-gray mb-1">ESTE CONTENIDO ES EXCLUSIVO</p>
    <h4 class="fw-900">PARA SUSCRIPTORES</h4>
    <h6>
        <a routerLink="/login">¿Ya eres suscriptor? Inicia sesión</a>
    </h6>
    </div>`,
})
export class SuscribeBlockComponent {}