import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-column-card',
  templateUrl: './column-card.component.html',
  styleUrls: ['./column-card.component.scss']
})
export class ColumnCardComponent implements OnInit {

  @Input() column: any = null
  @Input() type: 'column' | 'cover' | 'article' = 'column'
  @Input() tagPerception!: string
  @Input() showDate: boolean = true
  @Input() showAuthor: boolean = true
  @Input() showEdition: boolean = true
  @Input() newspaper: any = null
  @Input() showTags: any = null
  path: string = ''

  constructor() { }

  get routeParam() {
    if(this.column) {
      return this.column.hash ? {hash: this.column.hash} : {}
    }
    return {}
  }

  ngOnInit() {
    if(!this.type) this.type = 'column'
    this.path = this.type == 'column' ? 'opinion' : 'covers'
  }

  getPerception(): string {
    if(this.tagPerception) {
      let tags = [...this.column.tags]
      let perception
      if(this.type == 'cover'){
        perception = tags.find(t => t.tag == this.tagPerception)
      } else {
        perception = tags.find(t => t.tag?._id == this.tagPerception)
      }
      return (perception) ? perception.perceptions : this.column.perceptions
    }
    return this.column.perceptions
  }

}
