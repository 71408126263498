import { Injectable, OnInit, NgZone, Testability } from '@angular/core';
import { Router } from '@angular/router';
// import { ToastrService } from 'ngx-toastr';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { tap } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';
import { Tag } from '../interfaces/tag';

export interface User {
  uid: string;
  email: string;
  displayName: string;
  photoURL: string;
  emailVerified: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class AuthService implements OnInit {

  public userData: any;
  public showLoader: boolean = false;
  private apiUri = environment.apiUri

  constructor(
    public router: Router,
    public ngZone: NgZone,
    public snackbar: MatSnackBar,
    private http: HttpClient
  ) {
  }

  ngOnInit(): void { }


  login(email: string, password: string) {
    this.showLoader = true;
    return this.http.post(this.apiUri+'/client/login', {email, password}).toPromise()
      .then((result: any) => {
        if (result) {
          this.setToken(result.access_token);
          this.setUserData(result.user);
          // this.SendVerificationMail();
          this.showLoader = false;
          this.ngZone.run(() => {
            this.router.navigate(['/favs']);
          });
        } else {
          this.showLoader = false;
          this.ngZone.run(() => {
            this.router.navigate(['/login']);
          });
        }
      }).catch((error) => {
        this.showLoader = false;
        this.snackbar.open('El email o contraseña no coinciden.');
      })
  }

  getFavorites(params: any = null){
    let queryString = ''
    if(params){
      let urlParams = new HttpParams()
      for (const key in params) {
        urlParams = urlParams.append(key, params[key])
      }
      queryString = '?'+urlParams.toString()
    }
    return this.http.get(this.apiUri+'/clients/favorites' + queryString)
  }

  getEditions(){
    return this.http.get<any>(this.apiUri+'/clients/editions')
  }

  setFavorite(data: {tagId: string, follow: boolean}){
    return this.http.post(this.apiUri+'/clients/follow', data)
  }

  getFavoriteEdition() {
    const user = this.getUser();
    return user?.favoriteEdition;
  }

  getUser() {
    try {
      let local = localStorage.getItem('user')
      if(local)
        return JSON.parse(local)
      return null
    } catch (error) {
      return null
    }
  }

  setToken(access_token: any) {
    localStorage.setItem('token', access_token)
  }
  //Set user
  setUserData(user: any) {
    localStorage.setItem('user', JSON.stringify(user))
  }

  logout() {
    localStorage.clear()
    location.href = '/login'
  }

  get isLoggedIn(): boolean {
    const user = localStorage.getItem('user');
    if(!user) return false;
    const userObj: any = JSON.parse(user);
    return (userObj != null && userObj.emailVerified != false) ? true : false;
  }

  async isSubscriptionActive(){
    // Reload configuration in case of updates in profile
    await this.getConfiguration().toPromise();
    const user = localStorage.getItem('user');
    if (!user) return true;
    const clientObj: any = JSON.parse(user);
    return clientObj.subscriptionActive;
  }

  saveConfiguration(data: ConfigurationBot) {
    return this.http.post(this.apiUri+'/clients/configuration', data)
  }

  saveMidnight(midnightCut: boolean): Observable<ConfigurationBot> {
    return this.http.put<ConfigurationBot>(this.apiUri+'/clients/midnight', {midnightCut})
  }

  saveAlerts(alerts: boolean): Observable<ConfigurationBot> {
    return this.http.put<ConfigurationBot>(this.apiUri+'/clients/alerts', {alerts})
  }

  getConfiguration() {
    return this.http.get<ConfigurationBot>(this.apiUri+'/clients/configuration')
  }

  updateUser(data: any){
    return this.http.patch(this.apiUri+'/clients/profile', data).pipe(
      tap(this.setUserData)
    )
  }

  searchTags(name: string){
    return this.http.get<Tag[]>(this.apiUri+'/clients/searchtags?name='+name)
  }

  async hasEdition(edition: string): Promise<boolean> {
    let editions = await this.getEditions().toPromise();
    return editions.some((el: { key: string; }) => el.key == edition);
  }
}

export interface ConfigurationBot{
  cover: string
  column: string
  tag: string,
  midnightCut?: boolean
  alerts?: boolean
}
