import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { speedDialFabAnimations } from "./speed-dial-fab.animations";

@Component({
    selector: 'app-speed-dial-fab',
    templateUrl: './speed-dial-fab.component.html',
    styleUrls: ['./speed-dial-fab.component.scss'],
    animations: speedDialFabAnimations
  })
  export class SpeedDialFabComponent implements OnInit {

    @Input() title: string = ''
    @Input() showPdf = false
    @Output() download = new EventEmitter
  
    fabButtons = [
      {
        icon: 'share-2',
        class: 'bg-purple',
        action: 'share'
      },
      {
        icon: 'twitter',
        class: 'bg-twitter',
        action: 'twitter'
      },
      {
        icon: 'facebook',
        class: 'bg-facebook',
        action: 'facebook'
      },
      {
        icon: 'whatsapp',
        class: 'bg-whatsapp',
        action: 'whatsapp'
      },
    ];
    buttons: any = [];
    fabTogglerState = 'inactive';
  
    constructor() { }

    ngOnInit(): void {
      if(this.showPdf) {
        this.fabButtons[1] = {
          icon: 'file-text',
          class: 'bg-pdf',
          action: 'download'
        }
      }
    }
  
    showItems() {
      this.fabTogglerState = 'active';
      this.buttons = this.fabButtons;
    }
  
    hideItems() {
      this.fabTogglerState = 'inactive';
      this.buttons = [];
    }
  
    onToggleFab() {
      this.buttons.length ? this.hideItems() : this.showItems();
    }

    share(action = 'share') {
      let texttoShare = this.title + ' BrifMedia ' + location.href
      if(action == 'whatsapp') {
        window.open('https://wa.me/?text='+texttoShare, 'blank')
      }
      if(action == 'facebook')
        window.open('http://facebook.com/sharer/sharer.php?u='+location.href, 'blank')
      if(action == 'twitter')
        window.open('https://twitter.com/intent/tweet?status='+texttoShare, 'blank')

      if(action == 'download') {
        this.download.emit('trigger download');
        this.hideItems();
      }
      if(action!='share') return
      
      if (navigator.share) {
        navigator.share({
            title: this.title,
            text: 'BrifMedia',
            url: location.href,
          })
          .then(() => console.log('Successful share'))
          .catch((error) => console.log('Error sharing', error));
      } else {
        console.log('Share not supported on this browser, do it the old way.');
      }
    }
  }