<div class="container-fluid p-0">
    <div class="row">
      <div class="col-12">
        <div class="px-3 login-card">
          <div>
            <div>
              <div>
                <a class="logo" routerLink='/'>
                  <img class="d-md-none" src="assets/img/brif.png" alt="looginpage">
                  <img class="d-none d-md-block mx-auto" src="assets/img/logo.png" alt="looginpage">
                </a>
              </div>
            <div class="login-main login-main-login">
              <form class="theme-form" [formGroup]="loginForm">
                <h4>Iniciar sesión</h4>
                <p>Ingresa tus credenciales para acceder</p>
                <div class="form-group">
                  <label class="col-form-label">Email o usuario</label>
                  <input class="form-control" type="text" required="" placeholder="Email o usuario" formControlName="email">
                  <div class="show-hide text-muted"><app-feather-icons icon="user"></app-feather-icons></div>
                    <div *ngIf="loginForm.controls.email.touched && loginForm.controls.email.errors?.required"
                        class="text text-danger mt-1">
                        Email o usuario es requerido
                    </div>
                    <div *ngIf="loginForm.controls.email.touched && loginForm.controls.email.errors?.email"
                        class="text text-danger mt-1">
                        Email o usuario inválido
                    </div>
                </div>
                <div class="form-group">
                  <label class="col-form-label">Contraseña</label>
                  <input class="form-control" [type]="show ? 'text' : 'password'" formControlName="password" required="" placeholder="Contraseña">
                  <div class="show-hide" (click)="showPassword()" *ngIf="!show"><span class="show"></span></div>
                  <div class="show-hide" (click)="showPassword()" *ngIf="show"><span class="Hide"></span></div>
                  <div *ngIf="loginForm.controls.password.touched && loginForm.controls.password.errors?.required"
                        class="text text-danger mt-1">
                        Contraseña es requerida
                  </div>
                </div>
                <div class="form-group mb-0">
                  <div class="custom-control custom-checkbox p-0 my-4">
                    <input id="checkbox1" type="checkbox" class="custom-control-input">
                    <label class="text-muted" for="checkbox1" class="custom-control-label ms-2">Recordar contraseña</label>
                  </div>
                  <!-- <a [routerLink]="'/authentication/forgot-password'" class="link">Forgot password?</a> -->
                  <button class="btn btn-primary btn-block w-100"
                    [class.loader--text]="authService.showLoader"
                    [disabled]="!loginForm.valid || authService.showLoader"
                    (click)="login()"
                    type="button"><span>{{ authService.showLoader ? '' : 'Iniciar sesión' }}</span>
                  </button>
                </div>
                <!-- <h6 class="text-muted mt-4 or">Or Sign in with</h6> -->
                <!-- <div class="social mt-4">
                  <div class="btn-showcase">
                    <a class="btn btn-light" (click)="loginFacebook()" target="_blank">
                        <app-feather-icons [icon]="'facebook'" class="txt-fb"></app-feather-icons>Facebook
                    </a>
                    <a class="btn btn-light" (click)="loginTwitter()" target="_blank">
                     <app-feather-icons [icon]="'twitter'" class="txt-twitter"></app-feather-icons>twitter
                    </a>
                    <a class="btn btn-light" (click)="loginGoogle()" target="_blank">
                        <app-feather-icons [icon]="'log-in'" class="txt-google"></app-feather-icons>Google +
                    </a>
                  </div>
                </div> -->
                <hr>
                <p class="mt-4 mb-0 text-center font-weight-bold forgot">
                  <a [routerLink]="'/forgot-password'">¿Tienes problemas para ingresar?</a>
                </p>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
