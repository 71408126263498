import { Component, EventEmitter, Input, Output  } from '@angular/core';
import { ClientObj } from '../paywall.component';

interface ClientSchema {
  email: string
}

@Component({
  selector: 'app-client-form',
  templateUrl: './client-form.component.html',
  styleUrls: ['./client-form.component.scss']
})
export class ClientFormComponent {
  @Input() loading: boolean;
  @Input() errorRequest: string;
  @Output() backButton = new EventEmitter();
  @Output() submitButton = new EventEmitter();
  @Output() clientChanges = new EventEmitter<ClientObj>();
  constructor() { }

  onBack() {
    this.backButton.emit();
  }

  onSubmit() {
    this.submitButton.emit();
  }

  formIsInvalid(props: ClientSchema) {
    if (this.loading) {
      return true;
    }
    const filter = /^\S+@\S+\.\S+$/;
    return !filter.test(props.email);
  }

  onInputChange(type: string, value: string) {
    this.clientChanges.emit({type, value});
  }

}
