<div class="paywall py-5" *ngIf="plan">
  <div class="gradient">
  <div class="container d-flex flex-column align-items-center">
    <div class="plan-logo">
      <img width="60" src="assets/img/logo-simple.png" />
    </div>
    <div class="position-relative col-md-6">
      <img src="assets/img/torus2.svg" class="left-image" alt="left">
      <img src="assets/img/torushalf.svg" class="right-image" alt="right-image">
      <form class="mt-4 border mb-4" [formGroup]="form">
        <div class="plan-header">
          <h4 class="text-primary-dark fw-bold px-4 px-md-5 pt-4 pb-3">Contratar plan</h4>
        </div>
        <div class=" px-4 px-md-5 py-3 pt-md-4">
          <div class="text-primary fw-bold h6">Nombre completo</div>
          <input type="text" name="name" class="form-control" [class.valid]="form.get('name')?.valid && 
              (form.get('name')?.dirty || form.get('name')?.touched)"
          [class.is-invalid]="form.get('name')?.invalid && 
              (form.get('name')?.dirty || form.get('name')?.touched)" formControlName="name" placeholder="Ingresa tu nombre">
          <label *ngIf="form.get('name')?.errors?.required && (form.get('name')?.dirty || form.get('name')?.touched)" class="text-danger">Ingresa tu nombre completo</label>
        </div>
        <div class=" px-4 px-md-5 py-0 py-md-2">
          <div class="text-primary fw-bold h6">Correo electrónico</div>
          <input type="email" name="email" class="form-control"
            [class.is-invalid]="form.get('email')?.invalid && (form.get('email')?.dirty || form.get('email')?.touched)"
            formControlName="email" placeholder="Ingresa tu email">
          <label *ngIf="form.get('email')?.invalid && (form.get('email')?.dirty || form.get('email')?.touched)" class="text-danger">Ingresa un email válido</label>
        </div>
        <div class=" px-4 px-md-5 py-3 pb-md-4">
          <div class="text-primary fw-bold h6">Teléfono</div>
            <div class="input-group">
              <ng-select class="custom" aria-label="Example select" value="+52" [(ngModel)]="prefix" [ngModelOptions]="{standalone: true}" [clearable]="false">
                <ng-option [value]="prefix.dial_code" *ngFor="let prefix of phonePrefixes"><span class="font-emoji">{{prefix.emoji}}</span> {{prefix.dial_code}}</ng-option>
              </ng-select>
              <input type="text" name="phone" class="form-control" formControlName="phone" 
              [class.is-invalid]="form.get('phone')?.invalid && form.get('phone')?.dirty"
              placeholder="Ingresa tu número" maxlength="10">
            </div>
          <label *ngIf="form.get('phone')?.invalid && form.get('phone')?.dirty" class="text-danger">Ingresa los 10 dígitos</label>
        </div>
        
        <div class="px-4 px-md-5 py-4 fs-6 plan-block">
          <div class="row text-primary-dark fs-5 fw-bold mb-3">
            <div class="col-md-6">
              {{plan?.plan?.name}}
            </div>
            <div class="col-md-6 text-md-end">
              ${{plan?.plan?.amount | number: '1.0-0' }} MXN
            </div>
          </div>
          <div>
            Licencias: {{plan.licences}}
          </div>
          <div>
            Plazo: {{getInterval(plan.plan)}}
          </div>
          <div>
            Renovación automática al fin del plazo
          </div>
        </div>
        <div class="d-flex justify-content-between align-items-center px-4 px-md-5 py-4 plan-cards">
          <div class="d-flex tarjetas">
            <img src="/assets/img/MasterCard.svg" alt="Master Card"/>
            <img src="/assets/img/Visa.svg" alt="Visa"/>
            <img src="/assets/img/AMEX.svg" alt="Amex"/>
          </div>
          <button class="btn text-white" (click)="onSubmitData()" [disabled]="form.invalid">Ir a pagar</button>
        </div>
      </form>
    </div>
    
    <div class="fs-md-5 text-primary-dark mb-3 plan-monitoreo">
      #ElMonitoreoInteligente 🧠
    </div>
    <div class="text-center">
      <img src="/assets/img/conekta.png" alt="conekta" style="height: 32px;"/>
      <br>
      <span>
        Procesador de pago
      </span>
    </div>
  </div>
  </div>
</div>
<div *ngIf="notFound" class="h3">
  No encontrado
</div>