import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-skeleton',
  templateUrl: './skeleton.component.html',
  styles: [`.card-cover {
        height: 200px;
        display: flex;
        justify-content: center;
        overflow: hidden;
    }`]
})
export class SkeletonComponent {
  @Input() rows = 2;
  @Input() title = false;
}
