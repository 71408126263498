<div class="register">
  <div class="gradient">
  <div class="container d-flex flex-column align-items-center">
    <img width="70" class="mt-5" src="assets/img/logo-simple.png" />
    <form class="bg-white mt-4 p-4 border rounded mb-5 w-75">
      <div>
        <h4 class="title text-primary fw-bold pb-3">Detalles de registro</h4>
      </div>
      <h6 class="subtitle pb-3 text-primary">Información de registro</h6>
      <div class="register-form">
          <div class="d-flex flex-column">
            <label class="text-primary fw-bold">Nombre/s</label>
            <input  class="form-control fs-6" (change)="onInputChange('name', name.value)" #name placeholder="Ingresa tu/s nombre/s"/>
            <label *ngIf="formatError.name" class="text-danger">Formato incorrecto</label>
          </div>
          <div class="d-flex flex-column">
            <label class="text-primary fw-bold">Apellidos</label>
            <input  class="form-control fs-6" (change)="onInputChange('lastName', lastName.value)" #lastName placeholder="Ingresa tus apellidos"/>
            <label *ngIf="formatError.lastName" class="text-danger">Formato incorrecto</label>
          </div>
          <div class="d-flex flex-column">
            <label class="text-primary fw-bold">Correo electrónico</label>
            <input id="email" class="form-control fs-6" (change)="onInputChange('email', email.value)" #email placeholder="Ingresa tu correo electrónico"/>
            <label *ngIf="formatError.email" class="text-danger">Formato incorrecto</label>
          </div>
          <div class="d-flex flex-column">
            <label class="text-primary fw-bold">Número de teléfono</label>
            <input  class="form-control" (change)="onInputChange('phone', phone.value)" #phone placeholder="+52 222 333 4444"/>
            <label *ngIf="formatError.phone" class="text-danger">Formato incorrecto</label>
          </div>
          <div class="d-flex flex-column">
            <label class="text-primary fw-bold">Contraseña</label>
            <input type="password"  class="form-control fs-6" (change)="onInputChange('password', password.value)" #password placeholder="Ingresar contraseña"/>
            <label *ngIf="formatError.password" class="text-danger">Ingresa una contraseña más segura</label>
          </div>
          <div class="d-flex flex-column">
            <label class="text-primary fw-bold">Repetir contraseña</label>
            <input type="password" class="form-control fs-6" (change)="onInputChange('passwordRepeat', passwordConfirmation.value)" #passwordConfirmation placeholder="Repita la contraseña"/>
            <label *ngIf="passwordConfirmation.value && passwordConfirmation.value !== password.value" class="text-danger">Las contraseñas no coinciden</label>
          </div>
      </div>
      <div class="d-flex flex-column justify-content-center align-items-center mt-4">
        <div class="d-flex flex-column align-items-end">
          <div class="text-danger fw-bold mb-4" *ngIf="errorRequest">{{errorRequest}}</div>
          <div>
            <button class="btn text-white bg-primary"
              [disabled]="formIsInvalid({
                name: name.value,
                lastName: lastName.value,
                email: email.value,
                phone: phone.value,
                password: password.value,
                passwordConfirmation: passwordConfirmation.value
              })"
                (click)="$event.preventDefault(); onSubmit({
                  name: name.value,
                  lastName: lastName.value,
                  email: email.value,
                  phone: phone.value,
                  password: password.value
                });">{{loading ? 'Enviando...' : 'Continuar'}}</button>
          </div>
        </div>
      </div>
    </form>
  </div>
  </div>
</div>
