import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaywallComponent } from './paywall.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PlanSelectorComponent } from './plan-selector/plan-selector.component';
import { ClientFormComponent } from './client-form/client-form.component';
import { PlansComponent } from './plans/plans.component';
import { NgSelectModule } from '@ng-select/ng-select';

@NgModule({
  declarations: [
    PaywallComponent,
    PlanSelectorComponent,
    ClientFormComponent,
    PlansComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
  ]
})
export class PaywallModule { }
