    <form class="bg-white mt-4 p-4 border mb-5 ">
      <div>
        <h4 class="title fw-bold pb-3">Detalles de facturación</h4>
      </div>
      <h6 class="subtitle pb-3 mt-3">Selecciona tu plan</h6>
      <div class="plan-container">
        <div class="plan mt-3" (click)="onSelectPlan('premiumA')">
          <div class="d-flex justify-content-center align-items-center">
            <input type="radio" name="premiumA" [(ngModel)]="planType"  value="premiumA" />
          </div>
          <div class="d-flex flex-column">
            <label class="fs-6" [ngClass]="{'fw-bold': planType === 'premiumA' }">Plan Premium: facturación anual</label>
            <label class="text-secondary fs-6">Pago anual de ${{premiumAPrice | number: '3.0-0'}} MXN.</label>
          </div>
        </div>
        <div class="plan mt-3" (click)="onSelectPlan('premiumM')">
          <div class="d-flex justify-content-center align-items-center">
            <input type="radio" name="premiumM" [(ngModel)]="planType" value="premiumM" />
          </div>
          <div class="d-flex flex-column">
            <label class="fs-6" [ngClass]="{'fw-bold': planType === 'premiumM' }">Plan Premium: facturación mensual</label>
            <label class="text-secondary fs-6">Pago mensual de ${{premiumMPrice | number: '3.0-0' }} MXN.</label>
          </div>
        </div>
        <div class="plan mt-3" (click)="onSelectPlan('estandarA')">
          <div class="d-flex justify-content-center align-items-center">
            <input type="radio" name="estandarA" [(ngModel)]="planType"  value="estandarA" />
          </div>
          <div class="d-flex flex-column">
            <label class="fs-6" [ngClass]="{'fw-bold': planType === 'estandarA' }">Plan Estándar: facturación anual</label>
            <label class="text-secondary fs-6">Pago anual de ${{estandarAPrice | number: "3.0-0"}} MXN.</label>
          </div>
        </div>
        <div class="plan mt-3" (click)="onSelectPlan('estandarM')">
          <div class="d-flex justify-content-center align-items-center">
            <input type="radio" name="estandarM" [(ngModel)]="planType" value="estandarM" />
          </div>
          <div class="d-flex flex-column">
            <label class="fs-6" [ngClass]="{'fw-bold': planType === 'estandarM' }">Plan Estándar: facturación mensual</label>
            <label class="text-secondary fs-6">Pago mensual de ${{estandarMPrice | number: '3.0-0'}} MXN.</label>
          </div>
        </div>
        <div class="plan mt-4" (click)="onSelectPlan('basicoA')">
          <div class="d-flex justify-content-center align-items-center">
            <input type="radio" name="basicoA" [(ngModel)]="planType" value="basicoA" />
          </div>
          <div class="d-flex flex-column">
            <label class="fs-6" [ngClass]="{'fw-bold': planType === 'basicoA' }">Plan Básico: facturación anual</label>
            <label class="text-secondary fs-6">Pago anual de ${{basicoAPrice | number: '3.0-0'}} MXN.</label>
          </div>
        </div>
        <div class="plan mt-3" (click)="onSelectPlan('basicoM')">
          <div class="d-flex justify-content-center align-items-center">
            <input type="radio" name="basicoM" [(ngModel)]="planType" value="basicoM" />
          </div>
          <div class="d-flex flex-column">
            <label class="fs-6" [ngClass]="{'fw-bold': planType === 'basicoM' }">Plan Básico: facturación mensual</label>
            <label class="text-secondary fs-6">Pago mensual de ${{basicoMPrice | number: '3.0-0'}} MXN. </label>
          </div>
        </div>
      </div>
      <h6 class="subtitle pb-3 mt-5">Edición</h6>
      <div class="d-flex flex-column">
        <label class="fs-6 mb-2">
          <input class="me-2" type="checkbox" checked="checked" value="puebla">
          Puebla
        </label>
        <!-- <label class="fs-6 ">
          <input class="me-2" type="checkbox"  disabled="disabled" value="tlaxcala">
          Tlaxcala
        </label> -->
      </div>
      <h6 class="subtitle pb-3 mt-5">Forma de pago</h6>
      <div class="plan-tarjeta mt-3">
        <div class="d-flex justify-content-center align-items-center">
          <input type="radio" checked />
        </div>
        <div class="d-flex flex-column">
          <label class="fs-6 fw-bold mt-md-0 mt-2 mb-md-0 mb-2">Tarjeta de crédito o débito</label>
          <div class="d-flex mb-md-0 mb-2">
            <img src="/assets/img/locker.svg" alt="Secure"/>
            <label class="text-primary fs-6 ms-2">Pago seguro y encriptado</label>
          </div>
        </div>
        <div class="d-flex tarjetas">
          <img src="/assets/img/MasterCard.svg" alt="Master Card"/>
          <img src="/assets/img/Visa.svg" alt="Visa"/>
          <img src="/assets/img/AMEX.svg" alt="Amex"/>
        </div>
      </div>
      <div class="d-flex justify-content-between align-items-center mt-5">
        <label class="fs-6 ms-3 text-muted">
          Paso 1/2
        </label>
        <button class="btn text-white" (click)="onNextStep(planType)" [disabled]="!planType">Siguiente paso</button>
      </div>
    </form>
