import { Component, OnInit, Input } from '@angular/core';
import { SidebarService } from '../../services/sidebar.service';

@Component({
  selector: 'app-button-menu',
  templateUrl: './button-menu.component.html',
})
export class ButtonMenuComponent implements OnInit {

  open: boolean = false;

  constructor(
    private sidebarService: SidebarService
  ) { }

  ngOnInit() {
  }

  toggleSidebar(){
    this.open = !this.sidebarService.sidebarState.value
    this.sidebarService.sidebarState.next(this.open)
  }

}
