import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import { PlanTypes } from '../paywall.component';

@Component({
  selector: 'app-plan-selector',
  templateUrl: './plan-selector.component.html',
  styleUrls: ['./plan-selector.component.scss']
})

export class PlanSelectorComponent implements OnInit {

  constructor(private route: ActivatedRoute) { }

  @Output() nextButton = new EventEmitter<string>();

  planType: string;

  plans = {
    EstandarM: 'estandarM',
    EstandarA: 'estandarA',
    BasicoA: 'basicoA',
    BasicoM: 'basicoM',
    PremiumA: 'premiumA',
    PremiumM: 'premiumM'
  };


  planFrecuency: string;


  estandarMPrice: number = 399;
  estandarAPrice: number = 3_800;
  basicoMPrice: number = 199;
  basicoAPrice: number = 1_900;
  premiumMPrice: number = 799;
  premiumAPrice: number = 7_500;

  today: string;

  onSelectPlan(plan: string): void {
    this.planType = plan;
  }

  onNextStep(plan: string): void {
    this.nextButton.emit(plan);
  }

  ngOnInit(): void {
    this.route.queryParams
    .subscribe(params => {
      if (params.type && Object.values(PlanTypes)?.includes(params.type)) {
        this.planType = params.type;
      }
    });
    this.today = new Date().toLocaleString('es', {year: 'numeric', month: '2-digit', day: 'numeric'})
  }

}
