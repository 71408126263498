import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { FeatherIconsComponent } from './components/feather-icons/feather-icons.component';
import { PerceptionComponent } from './components/perception/perception.component';
import { AvatarPersonComponent } from './components/avatar-person/avatar-person.component';
import { ImagePipe } from './pipes/image.pipe';
import { ColumnCardComponent } from './components/column-card/column-card.component';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { SpeedDialFabComponent } from './components/speed-dial-fab/spped-dial-fab.component';
import { LimitPipe } from './pipes/limit.pipe';
import { ButtonMenuComponent } from './components/button-menu/button-menu.component';
import { SuscribeBlockComponent } from './components/suscribe-block/suscribe-block.component';
import { DateAgoPipe } from './pipes/date-ago.pipe';
import { VideoCardComponent } from './components/video-card/video-card.component';
import { NoInfoComponent } from './components/no-info/no-info.component';
import { VideoPlayerComponent } from './components/video-player/video-player.component';
import { SkeletonComponent } from './components/skeleton/skeleton.component';
import { SanitizePipe } from './pipes/satinize.pipe';
import { StripHtmlPipe } from './pipes/striphtml.pipe';
import { DebounceKeyupDirective } from './directives/debounce.directive';
import { NoEditionBlockComponent } from './components/no-edition/suscribe-block.component';
import { InstallPromptComponent } from './components/install-prompt/install-prompt.component';

@NgModule({
  declarations: [
    FeatherIconsComponent,
    PerceptionComponent,
    AvatarPersonComponent,
    ImagePipe,
    ColumnCardComponent,
    SpeedDialFabComponent,
    LimitPipe,
    ButtonMenuComponent,
    SuscribeBlockComponent,
    DateAgoPipe,
    VideoCardComponent,
    NoInfoComponent,
    VideoPlayerComponent,
    SkeletonComponent,
    StripHtmlPipe,
    SanitizePipe,
    DebounceKeyupDirective,
    NoEditionBlockComponent,
    InstallPromptComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    MatButtonModule,
  ],
  exports: [
    FeatherIconsComponent,
    PerceptionComponent,
    AvatarPersonComponent,
    ImagePipe,
    ColumnCardComponent,
    SpeedDialFabComponent,
    LimitPipe,
    ButtonMenuComponent,
    SuscribeBlockComponent,
    DateAgoPipe,
    VideoCardComponent,
    NoInfoComponent,
    VideoPlayerComponent,
    SkeletonComponent,
    StripHtmlPipe,
    SanitizePipe,
    DebounceKeyupDirective,
    NoEditionBlockComponent,
    InstallPromptComponent,
  ]
})
export class SharedModule { }
