import { Component } from '@angular/core';

@Component({
  selector: 'app-no-edition',
  template: `<div class="suscribe-block" style="padding-bottom: 5em;">
    <p class="text-dark-gray mb-1">NO ESTAS SUSCRITO A</p>
    <h4 class="fw-900">ESTA EDICIÓN</h4>
    <h6>
        <a routerLink="/favs">Volver</a>
    </h6>
    </div>`,
})
export class NoEditionBlockComponent {}