<div id="fab-dismiss" 
     *ngIf="fabTogglerState==='active'" 
     (click)="onToggleFab()">
</div>
<div class="fab-container">
    <button mat-mini-fab class="fab-toggler" color="{{fabTogglerState==='active'?'warn':'basic'}}"
            (click)="onToggleFab()">
            <app-feather-icons *ngIf="fabTogglerState!=='active'" icon="share-2"></app-feather-icons>
            <app-feather-icons *ngIf="fabTogglerState==='active'" icon="x"></app-feather-icons>
    </button>
    <div [@speedDialStagger]="buttons.length">
      <button *ngFor="let btn of buttons"
              mat-mini-fab
              (click)="share(btn.action)"
              [class]="btn.class"
              color="secondary">
        <app-feather-icons *ngIf="btn.icon!='whatsapp'" icon="{{btn.icon}}"></app-feather-icons>
        <svg *ngIf="btn.icon=='whatsapp'" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M17.7 14.352C18 14.508 18.192 14.592 18.252 14.712C18.324 14.844 18.3 15.444 18 16.128C17.76 16.8 16.512 17.448 15.96 17.472C15.408 17.496 15.396 17.904 12.408 16.596C9.42 15.288 7.62 12.096 7.476 11.892C7.332 11.688 6.324 10.236 6.372 8.76C6.432 7.296 7.2 6.6 7.512 6.312C7.8 6 8.124 5.964 8.328 6H8.892C9.072 6 9.324 5.928 9.552 6.54L10.38 8.784C10.452 8.94 10.5 9.12 10.392 9.312L10.068 9.804L9.6 10.308C9.456 10.452 9.288 10.608 9.456 10.908C9.6 11.22 10.2 12.216 11.04 13.044C12.132 14.1 13.092 14.448 13.38 14.604C13.668 14.772 13.848 14.748 14.028 14.556L15 13.428C15.228 13.128 15.42 13.2 15.696 13.296L17.7 14.352ZM12 0C18.624 0 24 5.376 24 12C24 18.624 18.624 24 12 24C9.636 24 7.44 23.316 5.58 22.14L0 24L1.86 18.42C0.684 16.56 0 14.364 0 12C0 5.376 5.376 0 12 0ZM12 2.4C6.696 2.4 2.4 6.696 2.4 12C2.4 14.064 3.048 15.972 4.152 17.532L3 21L6.468 19.848C8.028 20.952 9.936 21.6 12 21.6C17.304 21.6 21.6 17.304 21.6 12C21.6 6.696 17.304 2.4 12 2.4Z" fill="currentColor"/>
        </svg>
          
      </button>
    </div>
  </div>