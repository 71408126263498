<div class="installPrompt p-3 align-items-center bg-white d-md-none" [ngClass]="{
    'd-flex': showPrompt,
    'd-none': !showPrompt
  }">
    <div class="pe-3">
        <button class="btn-close" (click)="closePrompt()" aria-label="Close"></button>
    </div>
    <div class="flex-grow-1">
        <p class="mb-2">Brif Media</p>
        <span>Descarga en la Play Store</span>
    </div>
    <button class="btn btn-sm btn-primary" type="button" (click)="installClick()">
        Instalar
    </button>
</div>
