<div class="text-muted col-12" *ngIf="title">
    <p class="placeholder-wave">
        <span class="placeholder placeholder-lg col-6"></span>
    </p>
</div>
<div class="row mt-2 flex-nowrap overflow-auto" style="height: 200px;">
    <div class="col-6 col-md-3 px-1" *ngFor="let item of [1,2]">
        <div class="card p-0 card-cover placeholder-wave">
            <div class="placeholder w-100 bg-dark-gray h-100"></div>
        </div>
    </div>
</div>
<!-- <div class="text-muted">
    <p class="placeholder-wave">
        <span class="placeholder placeholder-lg col-6"></span>
    </p>
</div> -->
<div class="row mt-2 flex-nowrap overflow-auto" *ngIf="rows==2">
    <div class="col-6 col-md-3 px-1" *ngFor="let item of [1,2]">
        <div class="card p-0 card-cover placeholder-wave">
            <div class="placeholder w-100 bg-dark-gray h-100"></div>
        </div>
    </div>
</div>