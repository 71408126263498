import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-install-prompt',
  templateUrl: './install-prompt.component.html',
  styleUrls: ['./install-prompt.component.scss']
})
export class InstallPromptComponent implements OnInit {

  showPrompt: boolean = false;
  deferredPrompt: any;

  ngOnInit() {
    window.addEventListener('beforeinstallprompt', (e) => {
      // Prevent Chrome 67 and earlier from automatically showing the prompt
      e.preventDefault();
      // Stash the event so it can be triggered later.
      this.deferredPrompt = e;
      // Check if prompt was dismissed before
      const re = new RegExp(`(?<=dismissInstallPrompt=)[^;]*`);
      if (!document.cookie.match(re)) {
        // Update UI notify the user they can add to home screen
        this.showPrompt = true;
      } 
    });
  }

  dismissPrompt() {
    const dismissTime = new Date(new Date().getTime() + (6 * 3600 * 1000)); // dismiss 6h
    document.cookie = `dismissInstallPrompt=true; expires=${dismissTime.toUTCString()}; path=/`;
  }

  installClick() {
    this.showPrompt = false;
  // Show the prompt
    this.deferredPrompt.prompt();
  // Wait for the user to respond to the prompt
    this.deferredPrompt.userChoice
    .then((choiceResult: any) => {
      if (choiceResult.outcome === 'accepted') {
        console.log('User accepted the A2HS prompt');
      } else {
        console.log('User dismissed the A2HS prompt');
        this.dismissPrompt();
      }
      this.deferredPrompt = null;
    });
  }

  closePrompt() {
    this.showPrompt = false;
    this.dismissPrompt();
  }
}
