<div class="p-3 mb-5">
  <div class="d-flex justify-content-between">
    <app-button-menu></app-button-menu>
    <div class="config-button" routerLink="/configuration">
      <app-feather-icons icon="settings" class="mt-4"></app-feather-icons>
    </div>
  </div>
  <div class="d-flex flex-column justify-content-center align-items-center mt-4">
    <img width="200" src="assets/img/vencido.png"/>
    <h1 class="text-center text-primary mt-2">Tu plan ha vencido</h1>
    <h5 class="text-center mt-2">Hemos detectado que tu plan de cuenta presenta un vencimiento</h5>
    <h5 class="text-center fw-bold mt-2">¡Renueva tu plan de cuenta y sigue disfrutando de los servicios de BRIF MEDIA!</h5>
    <button routerLink="/pago" class="mb-5 border-0 bg-primary text-white fs-5 p-3 mt-3 rounded-pill" style="width: 50%">Renovar plan</button>
  </div>
</div>
