import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-avatar-person',
  templateUrl: './avatar-person.component.html',
})
export class AvatarPersonComponent implements OnInit {

  @Input() perception: any = 'Neutral'
  @Input('tag') person = {
      name: 'Miguel barbosa',
      avatar: 'https://avatars.githubusercontent.com/u/39237884?s=88&v=4'
  }

  constructor() { }

  ngOnInit() {
  }

}
