import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ClientSchema } from './register.component';

@Injectable({
  providedIn: 'root'
})
export class RegisterService {

  baseUri = environment.apiUri + '/clients';

  constructor(private http: HttpClient) { }

  registerUri = this.baseUri + '/registerClient';

  registerClient(body: ClientSchema) {
    return new Promise((resolve, reject) => {
      this.http.post(this.registerUri, body)
      .toPromise()
      .then((res: any) => {
        resolve(res);
      }, err => {
        reject(err);
      });
    });
  }

}
