import { Pipe, PipeTransform } from '@angular/core';
import { environment } from 'src/environments/environment';

@Pipe({
  name: 'image'
})
export class ImagePipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): string {
    if(!value) value = 'images/placeholder-tags.png'
    if(value.includes('://')||value.includes('data:image')) return value;
    return environment.imageBase + value;
  }

}
