import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-perception',
  templateUrl: './perception.component.html',
})
export class PerceptionComponent implements OnInit {

    @Input() perception: any = 'Neutral'

    types: any = {
        'Positivo': {
            class: 'bg-success',
            icon: 'fa fa-arrow-up'
        },
        'Neutro': {
            class: 'bg-warning',
            icon: 'fa fa-arrows-alt-h'
        },
        'Negativo': {
            class: 'bg-danger',
            icon: 'fa fa-arrow-down'
        },
    }
    item: any;

  constructor() { }

  ngOnInit() {
    if (Object.keys(this.types).includes(this.perception))
      this.item = this.types[this.perception]
  }

}
