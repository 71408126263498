<div class="paywall-failed">
  <div class="gradient">
  <div class="container d-flex flex-column align-items-center">
    <img width="70" class="mt-5" src="assets/img/logo-simple.png" />
    <form method="get" action="https://app.brif.media/pago" class="bg-white mt-4 border mb-5 d-flex flex-column align-items-center rounded rounded-5">
      <div class="mt-4 border-bottom w-100">
        <h5 class="title fw-bold pb-3 text-center text-primary">Facturación incompleta</h5>
      </div>
      <div class="d-flex flex-column align-items-center w-75">
        <img width="70" class="mt-3 mb-2" src="assets/img/no.png" />
        <p class="text-primary fs-6 text-center">Hemos tenido problemas asignando el plan, por favor intente nuevamente.</p>
        <p class="text-primary mb-5">Si el problema persiste contactenos via email
          <b>contacto@brif.media</b></p>
      </div>
      <div class="mb-4 border-top w-100 pt-4 d-flex flex-column align-items-center">
          <button class="btn btn-primary text-white">Intentar de nuevo</button>
      </div>
    </form>
  </div>
  </div>
</div>
