import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/shared/services/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  public show: boolean = false;
  public loginForm: FormGroup;
  public errorMessage: any;

  constructor(public authService: AuthService, private fb: FormBuilder,
    public snackbar: MatSnackBar,
    private route: ActivatedRoute) {
      this.loginForm = fb.group({
        email: ['', [Validators.required]],
        password: ['', Validators.required]
      });
  }

  ngOnInit() {
  }

  showPassword() {
    this.show = !this.show;
  }

  // Simple Login
  login() {
    this.authService.login(this.loginForm.value['email'].trim(), this.loginForm.value['password'].trim()).catch(error=>{
      this.snackbar.open('You have enter Wrong Email or Password.');
    });
  }

}
