import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PaymentBody, SubscriptionOrder } from '../paywall.component';
import { PaywallService, Plan, Planlink } from '../paywall.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { phonePrefixes } from 'src/app/shared/phone-prefix';

@Component({
  selector: 'app-plans',
  templateUrl: './plans.component.html',
  styleUrls: [
    './plans.component.scss',
    '../plan-selector/plan-selector.component.scss',
  ],
})
export class PlansComponent implements OnInit {
  email: string;
  name: string;
  prefix: string = '+52';
  loading: boolean;
  errorRequest: string;
  plan: Planlink;
  actualPlan: Plan;
  form: FormGroup;
  phonePrefixes = phonePrefixes;
  notFound: boolean;

  constructor(
    public paywallService: PaywallService,
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
  ) {
    this.route.paramMap.subscribe((paramMap) => {
      let id = paramMap.get('id');
      if (id) this.getPlanData(id);
    });
    this.form = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      name: ['', [Validators.required]],
      phone: ['', [Validators.required, Validators.minLength(10)]]
    })
  }

  ngOnInit(): void {}

  async getPlanData(id: string) {
    const planlink = await this.paywallService.getPlan(id);
    if(!planlink) {
      this.notFound = true
      return
    }
    this.actualPlan = planlink.plan;
    planlink.plan.amount = planlink.plan.amount / 100;
    this.plan = planlink;
  }

  async onSubmitData() {
    const body: SubscriptionOrder = {
      name: this.actualPlan.name,
      label: this.actualPlan.id,
      planId: this.actualPlan.id,
      customer: {
        ...this.form.value,
        phone: this.prefix + this.form.value.phone,
      },
      line_items: [
        {
          name: this.actualPlan.name,
          quantity: 1,
          unit_price: this.actualPlan.amount * 100,
        },
      ],
      subscribe: true,
      licences: this.plan.licences,
    };
    try {
      this.loading = true;
      const data: any = await this.paywallService.startPaymentSubscription(
        body
      );
      window.open(data.checkout.url);
      this.loading = false;
    } catch (errorResponse: any) {
      console.log(errorResponse);
      this.loading = false;
      this.errorRequest = 'Ha ocurrido un error procesando el pago';
    }
  }

  getInterval({interval, frequency}: Plan): string {
    if (interval == 'month') {
      switch (frequency) {
        case 1:
          return 'Mensual'
          break;
        case 2:
          return 'Bimestral'
          break;
        case 3:
          return 'Trimestral'
          break;
        case 6:
          return 'Semestral'
          break;
        case 12:
          return 'Anual'
          break;
        default:
          return 'Mensual'
          break;
      }
    } else {
      return Intervals[interval];
    }
  }
}

const Intervals = {
  month: 'Mensual',
  half_month: 'Quincenal',
  year: 'Anual',
  week: 'Semanal',
};
