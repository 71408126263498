    <form class="bg-white mt-4 p-4 border mb-5">
      <div>
        <h4 class="title fw-bold pb-3">Detalles de facturación</h4>
      </div>
      <h6 class="subtitle pb-3">Información de facturación</h6>
      <div class="d-flex flex-column">
        <label class="text-primary fw-bold">Correo electrónico</label>
        <input  class="form-control" type="email" (change)="onInputChange('email', email.value)" #email placeholder="Ingrese su correo electrónico"/>
      </div>
      <div class="d-flex flex-column justify-content-center align-items-center mt-5">
        <div class="d-flex flex-column align-items-end">
          <div class="text-danger fw-bold mb-4" *ngIf="errorRequest">{{errorRequest}}</div>
          <div>
            <button class="btn" (click)="onBack()">Ir atrás</button>
            <button class="btn text-white bg-primary"
              [disabled]="formIsInvalid({email: email.value})"
                (click)="onSubmit()">{{loading ? 'Enviando...' : 'Completar pago'}}</button>
          </div>
          <label class="text-muted mt-1">*Se abrira una ventana emergente para completar el pago</label>
        </div>
      </div>
    </form>
