import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PaywallFailedComponent } from './paywall/paywall-failed/paywall-failed.component';
import { PaywallSuccessComponent } from './paywall/paywall-success/paywall-success.component';
import { PaywallComponent } from './paywall/paywall.component';
import { DisabledUserComponent } from './auth/disabled-user/disabled-user.component';
import { LoggedGuard } from './shared/guards/logged.guard';
import { RegisterComponent } from './register/register.component';
import { RegisterSuccessComponent } from './register/register-success/register-success.component';
import { PlansComponent } from './paywall/plans/plans.component';

const routes: Routes = [{
  path: '',
  loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule),
  canActivate: [LoggedGuard]
},{
  path: '',
  loadChildren: () => import('./home/home.module').then(m => m.HomeModule),
},
{
  path: 'registro',
  component: RegisterComponent
},
{
  path: 'registro/exitoso',
  component: RegisterSuccessComponent
},
{
  path: 'pago',
  component: PaywallComponent
},
{
  path: 'pago/exitoso',
  component: PaywallSuccessComponent
},
{
  path: 'pago/fallido',
  component: PaywallFailedComponent
},
{
  path: 'suscripciones/:id',
  component: PlansComponent
},
{
  path: 'suspendido',
  component: DisabledUserComponent
},
{
  path: '**',
  redirectTo: 'login'
}];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
