<div class="card card-column h-100 mb-1 d-flex justify-content-between position-relative overflow-hidden"
    [routerLink]="['/'+path+'/id/'+column._id, routeParam]"
    *ngIf="column">
    <div>
        <small class="text-primary-dark text-capitalize" style="font-size: 11px;" *ngIf="column.edition&&showEdition">{{ column.edition }}</small>
        <br *ngIf="showTags">
        <div style="width: 24px; height: 24px;" class="me-2 mt-2 bg-white border border-primary rounded-circle d-inline-block overflow-hidden" *ngFor="let tag of showTags">
            <img class="w-100 avatarImg" [src]="tag?.avatar | image" *ngIf="showTags">
        </div>
        <h6 class="text-primary-title mb-3 title-font mt-2" [ngClass]="{
            'title-font-italic': type=='column'
        }">{{column.title}}</h6>
        <p class="text-muted-card content-card" [innerHTML]="column.text | striphtml | limit: 85">
        </p>
        <p class="text-primary" *ngIf="showAuthor">
          {{column.author?.name}}
        </p>
        <span *ngIf="showDate">
            {{column.publishedDate | dateAgo}}
        </span>
    </div>
    <div class="w-100 mt-1">
        <div class="d-flex justify-content-between align-items-center">
            <div class="d-flex" style="font-size: 1.25em;">
                <div style="width: 24px; height: 24px;" class="me-2 bg-white border rounded-circle d-inline-block overflow-hidden">
                    <img class="w-100 avatarImg" [src]="column?.newspaper?.avatar | image" *ngIf="column.exclusive!=false">
                    <img class="w-100 avatarImg" [src]="newspaper?.avatar | image" *ngIf="column.exclusive==false&&newspaper">
                    <div *ngIf="column.exclusive==false&&!newspaper" class="non-exclusive">+{{column.secondaries.length}}</div>
                </div>
                <app-perception *ngIf="tagPerception" perception="{{getPerception()}}"></app-perception>
            </div>
            <span class="text-primary-dark fw-900 me-2" *ngIf="type=='cover'">
                PORTADA
            </span>
            <span class="text-primary-dark fw-900 me-2" *ngIf="type=='column'">
                COLUMNA
            </span>
            <span class="text-primary-dark fw-900 me-2" *ngIf="type=='article'">
                ARTÍCULO
            </span>
        </div>
    </div>
    <div class="border-right-card"></div>
</div>
