import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import videojs, { VideoJsPlayerOptions } from 'video.js';
import 'videojs-youtube';

@Component({
  selector: 'app-video-player',
  template: `
    <video
      #target
      class="video-js w-100"
      controls
      playsinline
      preload="metadata"
      (timeupdate)="setTime($event)"
    ></video>
  `,
  encapsulation: ViewEncapsulation.None,
})
export class VideoPlayerComponent implements OnInit, OnDestroy {
  @ViewChild('target', { static: true }) target: ElementRef;
  @Input() options: VideoJsPlayerOptions;
  @Input() currentTime: any
  @Output() timeupdate = new EventEmitter;
  player: videojs.Player;

  constructor() {}

  ngOnInit() {
    // instantiate Video.js
    const time = this.currentTime
    if((this.options as any).youtube){
      setTimeout(() => {
        this.timeupdate.emit(time)
      }, 800)
    }
    this.player = videojs(
      this.target.nativeElement,
      {
        ...this.options,
        playbackRates: [1, 1.5, 2],
      },
      function onPlayerReady() {
        console.log('onPlayerReady', time);
        if (time){
          this.currentTime(time)
          this.autoplay(time?'any':false)
        }
      }
    );
  }

  ngOnDestroy() {
    // destroy player
    if (this.player) {
      this.player.dispose();
    }
  }

  setTime(event: any) {
    this.timeupdate.emit(event.target.currentTime)
  }
}