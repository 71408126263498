import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { PaymentBody } from '../paywall/paywall.component';
import { RegisterService } from './register.service';

export interface ClientSchema {
  email: string;
  lastName: string;
  name: string;
  phone: string;
  password: string;
  passwordConfirmation?: string;
}

interface StateRegister {
  type: string;
  data: {
    paymentDetails?: PaymentBody;
  }
}

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent {

  loading: boolean;
  errorRequest: string;
  paymentDetails: PaymentBody | undefined;
  type = 'personal';
  email: string = '';

  formatError = {
    name: false,
    lastName: false,
    email: false,
    phone: false,
    password: false,
  };

  constructor(public registerService: RegisterService, public router: Router) {
    const state = this.router.getCurrentNavigation()?.extras?.state as StateRegister;
    if (state) {
      this.type = state.type;
      this.paymentDetails = state.data.paymentDetails;
      // Falta precompletar el pago
    }
  }

  async onSubmit(props: ClientSchema) {
    try {
      const client = await this.registerService.registerClient(props);
      this.router.navigate(['/registro/exitoso'], { state: {
        type: this.type,
        data: {
          paymentDetails: this.paymentDetails
        }
      }});
    } catch (errorMessage: any) {
      this.errorRequest = errorMessage?.error?.message;
    }
  }

  formIsInvalid(props: ClientSchema) {
    if (this.loading) {
      return true;
    }
    const validationsNotOk = !Object.values(this.formatError)
    .every(item => {
      return item === false
    });
    return validationsNotOk || !props.name.length
    || !props.lastName.length || !props.email.length ||
    !props.password.length || !props.phone.length || !props.passwordConfirmation?.length
    || props.password !== props.passwordConfirmation;
  }

  onInputChange(type: string, value: string) {
    console.log(type, value);
    switch (type) {
      case 'name':
        this.formatError.name = value.length < 3;
        break;
      case 'lastName':
        this.formatError.lastName = value.length < 3;
        break;
      case 'email':
        this.email = value
        const filter = /^\S+@\S+\.\S+$/;
        this.formatError.email = !filter.test(value);
        break;
      case 'phone':
        const filterNumber = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/;
        this.formatError.phone = !filterNumber.test(value);
        break;
      case 'password':
        this.formatError.password = value.length < 6;
        break;
    }
  }
}

