import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { PaymentBody, SubscriptionOrder } from './paywall.component';

@Injectable({
  providedIn: 'root'
})
export class PaywallService {

  baseUri = environment.apiUri + '/paywall';
  planBaseUri = environment.apiUri + '/planlinks';

  constructor(private http: HttpClient) { }

  startPaymentUri = this.baseUri;

  startPayment(body: PaymentBody) {
    return new Promise((resolve, reject) => {
      this.http.post(this.startPaymentUri+'/order', body)
      .toPromise()
      .then((res: any) => {
        resolve(res);
      }, err => {
        reject(err);
      });
    });
  }

  startPaymentSubscription(body: SubscriptionOrder) {
    return new Promise((resolve, reject) => {
      this.http.post(this.startPaymentUri+'/subscription', body)
      .toPromise()
      .then((res: any) => {
        resolve(res);
      }, err => {
        reject(err);
      });
    });
  }

  getPlan(id: string) {
    return this.http.get<Planlink>(this.planBaseUri+'/'+id).toPromise()
  }
}

export interface Plan {
  amount: number;
  created_at: number;
  currency: string;
  expiry_count: number;
  frequency: number;
  id: string;
  interval: 'week' | 'half_month' | 'month' | 'year';
  livemode: boolean;
  name: string;
  object: 'plan';
}

export interface Planlink {
  licences: number;
  slug: string;
  plan: Plan;
}